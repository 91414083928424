import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../../components/Layout"
import * as styles from "../../styles/firm-philosophy.module.css"
import Sidebar from "../../components/Sidebar"
import { GatsbySeo } from "gatsby-plugin-next-seo"

export default function ConstructionLaw({ data }) {
  return (
    <Layout>
      <GatsbySeo
        title="Charleston South Carolina Maritime Lawyers | Tecklenburg, Jenkins & Jacoby, LLC "
        description="Tecklenburg, Jenkins & Jacoby, LLC is the only construction lawfirm you
            need. Our attorneys can serve all of your needs from pre-project
            legal services through post-construction project completion."
        keywords="Charleston, South Carolina, Maritime Attorney, Maritime Lawyer, Lawyer, Maritime, Attorney, Admiralty Attorney, Admiralty Lawyer, Admiralty, Business Attorney, Business Lawyer, Business, Railroad Attorney, Railroad Lawyer, Railroad, Litigation Attorney, Litigation Lawyer, Litigation, Construction Attorney, Construction Lawyer, Construction, Estate Attorney, Estate Lawyer, Estate"
        canonical="https://www.tecklaw.net/practice-areas/south-carolina-construction-law/"
      />
      <section className={styles.header}>
        <section className={styles.sidebar}>
          <Sidebar data={data} />
        </section>
        <section className={styles.content}>
          <h1>South Carolina Construction Lawyers</h1>
          {/* <h2>CONSTRUCTION LAW</h2> */}
          <h2>Experienced Construction Attorneys Provide Solid Advice</h2>
          <p>
            Tecklenburg, Jenkins & Jacoby, LLC is the only construction lawfirm
            you need. Our attorneys can serve all of your needs from pre-project
            legal services through post-construction project completion. We deal
            with issues as they arise, such as unforeseen accidents and delays,
            but also proactively anticipate problems. Our litigation attorneys
            also handle disputes that that may require arbitration or
            litigation.
          </p>
          <h2>
            South Carolina Construction Lawyers to Help You Build Your Business
          </h2>
          <p>
            Our ability to evaluate construction projects helps clients
            understand the legal matters related to any project. We help all
            parties involved, including contractors or owners, to understand
            clearly the particular project guidelines, timelines and what
            actions to take when problems arise. The goal in drafting the
            contract is not only to protect your interests, but also to avoid
            any disputes between the parties and unnecessary costs to you in
            resolving those disputes. Our construction attorneys will use their
            experience to draft and review the terms and conditions of your
            construction contract including:
          </p>
          <ul>
            <li>
              <h3>Defining the Scope Of Work</h3>
            </li>
            <li>
              <h3>Detailing the Specifications</h3>
            </li>
            <li>
              <h3>Formulating Payment and Completion Schedules</h3>
            </li>
            <li>
              <h3>Assessing Insurance Requirements</h3>
            </li>
            <li>
              <h3>Identifying Payment and Performance Bond Issues</h3>
            </li>
            <li>
              <h3>Negotiating Termination and Cancellation Alternatives</h3>
            </li>
            <li>
              <h3>Defining Indemnification Requirements</h3>
            </li>
            <li>
              <h3>Detailing Architectural and Engineering Provisions</h3>
            </li>
          </ul>
          <h2>
            Determined Construction{" "}
            <Link to="/practice-areas/south-carolina-litigation">
              Litigation
            </Link>{" "}
            Attorneys to Defend Your Rights
          </h2>
          <p>
            At the law offices of Tecklenburg, Jenkins & Jacoby, LLC, we
            routinely respond to a variety of serious construction disputes with
            practical, experienced, and effective advice that saves our clients
            time and money. We regularly represent clients in the defense of
            actions, as well as the pursuit of claims and counterclaims. Our
            experienced lawyers represent the interests of our clients in
            matters such as:
          </p>
          <ul>
            <li>
              <h3>Residential Construction Claims</h3>
            </li>
            <li>
              <h3>Commercial Construction Claims</h3>
            </li>
            <li>
              <h3>Mechanic’s Liens</h3>
            </li>
            <li>
              <h3>Bond Claims</h3>
            </li>
            <li>
              <h3>Change and Delay Claims</h3>
            </li>
            <li>
              <h3>Mediation</h3>
            </li>
            <li>
              <h3>Payment Issues</h3>
            </li>
            <li>
              <h3>UCC Claims and Filings</h3>
            </li>
            <li>
              <h3>Defective Plans and Specifications</h3>
            </li>
            <li>
              <h3>Faulty Products</h3>
            </li>
            <li>
              <h3>Insurance Coverage Disputes</h3>
            </li>
            <li>
              <h3>Construction Accidents</h3>
            </li>
            <li>
              <h3>Government Contract Disputes</h3>
            </li>
            <li>
              <h3>Breach of Contract</h3>
            </li>
          </ul>
          <p>
            If you are in need of an experienced, knowledgeable construction
            attorney, please <Link to="/contact">contact</Link> us today.
          </p>
        </section>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query ConstructionLawSidebarImages {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "south-carolina-construction-law" }
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
